import React from 'react';
import './StyledDisplayText.css';

const StyledDisplayText = ({ content }) => {
  return (
    <div className={`styled-display-text`}>
      <div className="text-center" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default StyledDisplayText;
